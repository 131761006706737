import { NavLink } from "react-router-dom";
import css from "./Logo.module.scss";

const Logo = () => {
  return (
    <NavLink to="/" className={css.logo}>
          <img src='https://i.ibb.co/1TYvTwc/Beedumy-logo.webp' alt='logo' loading="lazy" className={css.image} />

     
    </NavLink>
  );
};

export default Logo;
